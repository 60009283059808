<template>
  <div class="mod-green_application_information}">
    <div>
      <!-- <div>用户基本信息</div> -->
      <el-card>
        <div slot="header" class="clearfix">
          <span>{{ title }}</span>
          <span v-if="showHeaderButton">
            <el-button style="float: right; padding: 3px 3px" type="text" v-if="$hasPermission('miaoins')" @click="editorApplicationInformation()">编辑</el-button>
            <el-button style="float: right; padding: 3px 3px" type="text" v-if="$hasPermission('miaoins')" @click="saveApplicationInformation()">保存</el-button>
          </span>
          <!-- <span>{{ greenss }}</span> -->
        </div>
        <div class="basic_information">
          <el-form :inline="true" :disabled="disabled" :model="getGreenApplicationInformation" ref="getGreenApplicationInformation" :rules="dataRule" class="demo-form-inline">
            <!-- <el-form-item label="地区" v-if="showTCM">
              <el-input
                v-model="getGreenApplicationInformation.address"
                placeholder="地区"
              ></el-input>
            </el-form-item> -->
            <el-form-item label="入住时间" prop="applyHospitalStartDate" v-if="showTCM">
              <el-date-picker value-format="yyyy-MM-dd" v-model="getGreenApplicationInformation.applyHospitalStartDate" type="date" placeholder="选择日期"></el-date-picker>
            </el-form-item>
            <el-form-item label="地区" prop="selectedOptions" v-if="showTCM">
              <el-cascader v-model="getGreenApplicationInformation.selectedOptions" :options="options" :props="{ expandTrigger: 'hover' }" @change="handleChange"></el-cascader>
            </el-form-item>
            <el-form-item label="服务地址" v-if="showTCM">
              <el-input v-model="getGreenApplicationInformation.address" placeholder="服务地址"></el-input>
            </el-form-item>

            <el-form-item :label="appealDescLable">
              <el-input type="textarea" v-model="getGreenApplicationInformation.appealDesc" placeholder="诉求描述"></el-input>
            </el-form-item>
          </el-form>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import { regionData } from "element-china-area-data";
import debounce from "lodash/debounce";
import { treeDataTranslate } from "@/utils";
export default {
  data() {
    return {
      header_button: true,
      showHeaderButton: true,
      disabled: true,
      showAddress: false,
      showTCM: false,
      applyTypeData: [
        { id: 1, name: "住院绿通" },
        { id: 2, name: "门诊绿通" },
        { id: 3, name: "院中陪护" },
      ],
      greenStatusOptions: [
        { id: 0, name: "初审中" },
        { id: 1, name: "初审不通过" },
        { id: 2, name: "需求确认" },
        { id: 3, name: "预约中" },
        { id: 4, name: "预约成功" },
        { id: 6, name: "结案" },
        { id: 7, name: "受理中" },
      ],
      applyHospitalData: [], // 医院
      applyDepartmentData: [], // 科室
      getGreenApplicationInformation: {
        id: "",
        greenNo: "",
        type: "",
        applyHospitalStartDate: "",
        applyHospitalEndDate: "",
        applyHospital: "",
        applyDepartment: "",
        doctorName: "",
        greenStatus: "",
        appealDesc: "",
        selectedOptions: [],
      },
      rightCode: "",
      ticketId: "",
      options: [],
      selectedOptions: [],
      title: "申请信息",
      appealDescLable: "诉求描述",
      showIllness: "",
    };
  },
  computed: {
    dataRule() {
      return {
        applyHospitalStartDate: [
          {
            required: true,
            message: this.$t("validate.required"),
            trigger: "blur",
          },
        ],
        applyHospitalEndDate: [
          {
            required: true,
            message: this.$t("validate.required"),
            trigger: "blur",
          },
        ],
        selectedOptions: [
          {
            required: true,
            message: this.$t("validate.required"),
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: this.$t("validate.required"),
            trigger: "blur",
          },
        ],
      };
    },
  },
  components: {
    // AddOrUpdate
  },
  created() {
    // this.getGreenApplicationInformations()
    // this.getapplyHospitalData()
    // this.getapplyDepartmentData()
  },
  methods: {
    greenFun(params) {
      console.log("点击事件", params);
      this.getGreenApplicationInformation = {
        ...this.getGreenApplicationInformation,
        ...params.entityDetails,
      };
      this.ticketId = params.id;
      let regionData = [];
      regionData.push(params.entityDetails.province);
      regionData.push(params.entityDetails.city);
      regionData.push(params.entityDetails.district);
      this.getGreenApplicationInformation.selectedOptions = regionData;
      console.log(regionData);
      if (params.status === 2 || params.status === 0) {
        this.showHeaderButton = false;
      } else {
        this.showHeaderButton = true;
      }
      this.rightCode = params.rightCode;
      this.title = params.rightName;
      if (params.rightCode === "ONSITE_PHYSICAL_THERAPY") {
        this.showIllness = false;
        this.showTCM = true;
        this.appealDescLable = "服务简述";
      } else {
        this.showIllness = true;
      }
      this.getAreaList();
    },
    getAreaList() {
      this.$http
        .get(`/sys/region/tree`)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          let dataList = res.data;
          for (let i in dataList) {
            dataList[i].value = dataList[i].id;
            dataList[i].label = dataList[i].name;
          }

          let dataList2 = treeDataTranslate(dataList);
          this.options = dataList2;
        })
        .catch(() => {});
    },
    // 编辑
    editorApplicationInformation() {
      this.header_button = false;
      this.disabled = false;
      this.$emit("fatherMethod");
    },
    // 保存
    saveApplicationInformation() {
    
      if (!this.getGreenApplicationInformation.appealDesc) {
        return this.$message.error("需求描述不能为空!");
      }
     
      this.getGreenApplicationInformation.ticketId = Number(this.ticketId);
      this.$http
        .post(`/healthbusiness/health-green-channel/update`, this.getGreenApplicationInformation)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return this.$message.error(res.msg);
          }
          this.$message({
            message: "保存成功",
            type: "success",
            duration: 500,
            onClose: () => {
              this.visible = false;
              this.header_button = true;
              this.disabled = true;
              // this.getGreenApplicationInformations();
            },
          });
        })
        .catch(() => {});
      // });
    },
    handleChange(e) {
      console.log(this.selectedOptions);
      this.getGreenApplicationInformation.province = e[0];
      this.getGreenApplicationInformation.city = e[1];
      this.getGreenApplicationInformation.district = e[2];
    },
  },
};
</script>
<style scoped>
.clearfix {
  font-size: 20px;
  font-weight: 700;
}
.el-card {
  border-top: 3px solid rgba(3, 165, 151, 0.616);
}
.el-card /deep/.el-card__body {
  padding-bottom: 0px;
}
.basic_information {
  /* width: 90%; */
  /* border: 1px solid #cccccc; */
}
.input {
  display: inline-block;
}
.demo-form-inline {
  margin-left: 20px;
}
.el-form--inline .el-form-item {
  margin-right: 30px;
}
</style>
<style>
/deep/.el-input {
  color: #000 !;
}
/deep/.el-input.is-disabled .el-input__inner {
  color: #000;
}
</style>
